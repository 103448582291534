/**
 * @module countdown
 * @description Create and optionally run countdown. For configuration parameters {@see Countdown.constructor}
 * @example Countdown({
 *  dateEnd: new Date((new Date).setFullYear(2019)),
 *  dateStart: Date.now()
 *});
 */
/**
 * @class
 */
export class Countdown {
    static get CONVERSION_TABLE() {
        return {
            // years: 31536000,
            // months: 262800,
            weeks: 604800,
            days: 86400,
            hours: 3600,
            minutes: 60,
            seconds: 1,
        };
    }

    /**
     * @param {object} config
     * @param {Date} [config.dateStart]
     * @param {Date} [config.dateEnd]
     * @param {Function} [config.onStart]
     * @param {Function} [config.onEnd]
     * @param {Function} [config.onTick]
     * @param {boolean} [config.initialize]
     * @param {number} [config.interval]
     */
    constructor(config) {
        const defaultConfig = {
            // Dates
            dateStart: new Date(),
            dateEnd: new Date(Date.now() + 24 * 60 * 60 * 1000),

            // Callbacks
            onStart: null,
            onTick: null,
            onEnd: null,

            // Extra options
            selfInitialize: true,
            interval: 1000,
        };
        this.config = Object.assign({}, defaultConfig, config);

        this.started = false;
        this.interval = this.config.interval;

        // Doing all the things!
        if (this.config.selfInitialize !== false) {
            this.initialize();
        }
    }

    /**
     * Initializing the instance
     */
    initialize() {
        // Already over
        if (this.isOver()) {
            this.callback('end');
            return;
        }

        this.run();
    }

    /**
     * Converting a date into seconds
     * @param {Date} date
     * @returns {number}
     */
    static seconds(date) {
        return date.getTime() / 1000;
    }

    /**
     * Returning if countdown has started yet
     * @returns {boolean}
     */
    isStarted() {
        return Countdown.seconds(new Date()) >= Countdown.seconds(this.config.dateStart);
    }

    /**
     * Returning if countdown is over yet
     * @returns {boolean}
     */
    isOver() {
        return Countdown.seconds(new Date()) >= Countdown.seconds(this.config.dateEnd);
    }

    /**
     * Running the countdown
     */
    run() {
        let sec = Math.abs(Countdown.seconds(this.config.dateEnd) - Countdown.seconds(new Date()));

        // Initial display before first tick
        if (this.isStarted()) {
            this.display(sec);
        }

        const timer = window.setInterval(() => {
            sec -= this.interval / 1000;

            // Time over
            if (sec <= 0) {
                window.clearInterval(timer);
                this.callback('end');
            } else if (this.isStarted()) {
                if (!this.started) {
                    this.callback('start');
                    this.started = true;
                }

                this.display(sec);
            }
        }, this.interval);
    }

    /**
     * Displaying the countdown
     * @param {number} sec
     */
    display(sec) {
        const countdownProperties = {};

        const conversionKey = Object.keys(Countdown.CONVERSION_TABLE);
        let convertedSecs;
        conversionKey.forEach((key) => {
            const propertySeconds = Countdown.CONVERSION_TABLE[key];
            convertedSecs = Math.floor(sec / propertySeconds);
            sec -= convertedSecs * propertySeconds;

            countdownProperties[key] = convertedSecs;
        });
        if (this.config.onTick !== null) this.config.onTick(countdownProperties, this);
    }

    /**
     * Dealing with events and callbacks
     * @param {string} eventName
     */
    callback(eventName) {
        const eventCapitalizeName = this._capitalizeString(eventName);
        const eventType = `on${eventCapitalizeName}`;

        // onStart callback
        if (typeof this.config[eventType] === 'function') {
            this.config[eventType]();
        }
    }

    _capitalizeString(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
